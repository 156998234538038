<script>
import { init } from 'events';

export default {
  props: ['title', 'minLimit', 'maxLimit', 'min', 'max', 'tooltip'],
  emits: ['min-changed', 'max-changed'],
  data() {
    return {
      selectedMin: this.min,
      selectedMax: this.max
    }
  },
  computed: {
    sliderMin: {
      get: function() {
        let val = parseInt(this.selectedMin);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        if (val > this.selectedMax) {
          this.selectedMax = val;
        }
        this.selectedMin = val;
        this.$emit('min-changed', val);
      }
    },
    sliderMax: {
      get: function() {
        let val = parseInt(this.selectedMax);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        if (val < this.selectedMin) {
          this.selectedMin = val;
        }
        this.selectedMax = val;
        this.$emit('max-changed', val);
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="range-slider">
      <div class="slider-title">
          <label for="range">
            <span v-html="title"></span><span>: {{ sliderMin }} to {{ sliderMax }} </span>
            <b-link class="info" v-b-tooltip.hover.html :title="tooltip">i</b-link>
          </label>
      </div>
      <input v-model="sliderMin" :min="minLimit" :max="maxLimit" step="1" type="range" />
      <input v-model="sliderMax" :min="minLimit" :max="maxLimit" step="1" type="range" />
      <svg width="100%" height="24">
        <line x1="4" y1="0" x2="300" y2="0" stroke="#444" stroke-width="12" stroke-dasharray="1 28"></line>
      </svg>
    </div>
  </div>
</template>

<style>
  .range-slider {
    text-align: left;
    position: relative;
    height: 60px;
    margin-bottom: 10px;
  }

  .range-slider svg,
  .range-slider input[type=range] {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  a.info, a.info:hover {
    border-style: solid;
    font-size: 11px;
    background-color: rgb(157, 166, 193);
    color: #ffffff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    font-style: italic;
    font-weight: 600;
  }

  .slider-title label,
  .slider-title div
  .slider-title svg {
    display: inline-block;
  }

  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: #9da6c0;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
  }

  input[type=range]::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #627b8b;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #839ab1;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }

</style>