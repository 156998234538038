<script>
export default {
  props: ['city', 'selectedMonth'],
  emits: [],
  data() {
    return {
        // city: this.city,
        // selectedMonth: this.selectedMonth,
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    }
  },
  computed: {
    minTemperature() {
        const t = this.city.t_min[this.selectedMonth];
        return (t > 0 ? '+' + t : t) + ' &#8451;';
    },
    avgTemperature() {
        const t = this.city.t_avg[this.selectedMonth];
        return (t > 0 ? '+' + t : t) + ' &#8451;';
    },
    maxTemperature() {
        const t = this.city.t_max[this.selectedMonth];
        return (t > 0 ? '+' + t : t) + ' &#8451;';
    }
  }
}
</script>

<template>
    <b-container fluid style="min-width: 250px">
        <b-row class="title">
            <b-col>{{ city.name }}, {{ city.country }}:</b-col>
        </b-row>
        <b-row class="info score">
            <b-col>Overall score:</b-col>
            <b-col>{{ city.overallIndex }} / 100</b-col>
        </b-row>
        <b-row class="info">
        <b-col>Affordability:</b-col>
        <b-col><b-progress style="width:100%" :value="city.costIndex" variant="secondary" show-progress></b-progress></b-col>
        </b-row>
        <b-row class="info">
        <b-col>Safety:</b-col>
        <b-col><b-progress style="width:100%" :value="city.safetyIndex" variant="secondary" show-progress></b-progress></b-col>
        </b-row>
        <hr />
        <b-row class="info">
            <b-col>Temperatures in {{ this.months[selectedMonth] }}:</b-col>
        </b-row>
        <b-row class="info">
            <b-col cols="3">Min</b-col>
            <b-col cols="3">Average</b-col>
            <b-col cols="3">Max</b-col>
        </b-row>
        <b-row class="info">
            <b-col cols="3" v-html="minTemperature"></b-col>
            <b-col cols="3" v-html="avgTemperature"></b-col>
            <b-col cols="3" v-html="maxTemperature"></b-col>
        </b-row>
        <!-- <b-row class="info">
        <b-col>Min:</b-col>
        <b-col>{{ minTemperature }}</b-col>
        </b-row>
        <b-row class="info">
        <b-col>Average:</b-col>
        <b-col>{{ avgTemperature }}</b-col>
        </b-row>
        <b-row class="info">
        <b-col>Max:</b-col>
        <b-col>{{ maxTemperature }}</b-col>
        </b-row> -->
    </b-container>
</template>

<style>

.row.title {
    font-size: 16px;
    font-weight: 600;
}

.row.score {
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 5px;
}

.row.info {
    font-size: 15px;
}

.info .col-3 {
    min-width: 83px;
}

</style>