import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import App from "./App";
import VueRouter from 'vue-router';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "leaflet/dist/leaflet.css";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueRouter)


const router = new VueRouter({
    routes: [{ name: 'home', path: '/:map', component: App }]
})

// new Vue({ router, el: "#app", render: h => h(App) });

const app = new Vue({
    router,render: h => h(App)
  }).$mount('#app')