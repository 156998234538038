<template>
  <div id="content">
    <div>
    <b-button-group id="sidebar-button-group" vertical>
      <b-button class="btn btn-primary rounded-0 d-flex flex-column align-items-center" v-b-toggle.sidebar-filters title="Filters">
        <b-icon-funnel></b-icon-funnel>
        <span class="btn-title">Filters</span>
      </b-button>
      <b-button class="btn btn-primary rounded-0 d-flex flex-column align-items-center" v-b-toggle.sidebar-preferences title="Preferences">
        <b-icon-gear></b-icon-gear>
        <span class="btn-title">Preferences</span>
      </b-button>
    </b-button-group>
  </div>
    <l-map ref="map" :maxBounds="maxBounds"
    :zoom="zoom" :minZoom=2 :center="center" :options="{zoomControl: false}"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        @update:bounds="boundsUpdated">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-control-zoom position="bottomright"></l-control-zoom>
        <l-circle-marker v-for="city in citiesComputed" :key="city.name + '-' + city.country" :lat-lng="city.coordinates" :radius=circleRadius :stroke=false :fillColor="city.color" :fillOpacity="0.9">
          <!-- <l-popup><city-info :city="city" :selected-month="selectedMonth" /></l-popup> -->
          <l-tooltip :options={sticky:true} ><city-info :city="city" :selected-month="selectedMonth" /></l-tooltip>
        </l-circle-marker>

        <l-layer-group ref="indexLabelsLayer" :visible="indexLayerVisible">
          <l-marker :style="{ width: circleLabelSize + 'px', height: circleLabelSize + 'px' }" v-for="city in citiesComputed" :key="city.name + '-' + city.country + 'title'" :lat-lng="city.coordinates" :zIndexOffset="1500">
            <!-- <l-popup><city-info :city="city" :selected-month="selectedMonth" /></l-popup> -->
            <l-tooltip :options={sticky:true} ><city-info :city="city" :selected-month="selectedMonth" /></l-tooltip>
          <l-icon :iconSize=[circleLabelSize,circleLabelSize] :style="{ width: circleLabelSize + 'px', height: circleLabelSize + 'px' }" class-name="index-label">
            <div class="d-flex align-items-centerh-100 d-flex justify-content-center align-items-center" :style="{ width: circleLabelSize + 'px', height: circleLabelSize + 'px', fontSize: circleLabelFontSize + 'px', opacity: circleLabelOpacity }">{{ city.overallIndex }}</div>
            </l-icon>
        </l-marker>
      </l-layer-group>
      </l-map>
    <b-sidebar id="sidebar-filters" title="Filters" shadow>
      <!-- <div style="witdh:100%; margin-left: 20px;"><img src="./assets/logo2.png" height="50px" /></div> -->
      <div class="px-3 py-2">
        <div class="sidebar-container">
          <div>
          <range-selector title="Affordability index" tooltip="Relative affordability of the city:<br/><em>1 - very expensive</em><br/><em>100 - very cheap</em>" min-limit="1" max-limit="100" :min="costIndexMin" :max="costIndexMax"
            @min-changed="(val) => costIndexMin = val" @max-changed="(val) => costIndexMax = val" />
          </div>
          <div>
          <range-selector title="Safety index" tooltip="Relative safety of the city:<br/><em>1 - very dangerous</em><br/><em>100 - very safe</em>" min-limit="1" max-limit="100" min="1" max="100"
            @min-changed="(val) => safetyIndexMin = val" @max-changed="(val) => safetyIndexMax = val" />
          </div>
          <div>
            <b-form-select v-model="selectedMonth" :options="monthOptions"></b-form-select>
            <range-selector :key="'temp-selector-' + selectedMonth" title="Temperature range (&#8451;)" tooltip="Average temperature in the city for the selected month" :min-limit="minTempLimit" :max-limit="maxTempLimit" :min="minTempLimit" :max="maxTempLimit"
              @min-changed="(val) => tempMin = val" @max-changed="(val) => tempMax = val" />
          </div>
        </div>
      </div>
    </b-sidebar>
    <b-sidebar id="sidebar-preferences" title="Preferences" shadow>
      
    </b-sidebar>
  </div>
</template>

<style>
  #content {
    width: 100%;
    height: 100%;
  }

  html, body, #map {
      height: 100%;
      width: 100%;
  }

  #sidebar-button-group {
    position: absolute;
    z-index: 500;
    /* top: 5px;
    left: 5px; */
  }

  #sidebar-button-group .btn {
    padding: 15px 10px 15px 10px;
  }

  #sidebar-button-group .btn-title {
    font-size: x-small;
  }

  .sidebar-container > div {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(229, 229, 229);
  }

  .priority-box label{
    margin-right: 15px;
  }

  .priority-box span {
    font-weight: 500;
  }

  .priority-box output {
    background-color: transparent;
  }

  .hidden {
    display: none;
    visibility: hidden;
  }

  /* Leaflet zoom controls style */
  /* .leaflet-bar a { color: red; } */

</style>

<script>
import { latLng, latLngBounds } from "leaflet";
import {LMap, LTileLayer, LCircleMarker, LTooltip, LPopup, LMarker, LControlZoom, LIcon, LLayerGroup} from 'vue2-leaflet';
import citiesData from './assets/data.json';
import RangeSelector from "./components/RangeSelector.vue";
import CityInfo from "./components/CityInfo.vue";


const maxScore = 99;
const mapRouteRegex = /@(-?\d+(\.\d+)?),(-?\d+(\.\d+)?),(\d+)z/;

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const colors = ['#961a17', '#e61c1c', '#e65f1c', '#e6a21c', '#f7d540', '#bee61e', '#5fe61c', '#3ebb3e', '#298529'];
function getColor(city) {
  let reducedIndex = Math.trunc(city.overallIndex * (colors.length - 1) / maxScore);
  return colors[reducedIndex];
}

export default {
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
    LTooltip,
    LPopup,
    LIcon,
    LMarker,
    LControlZoom,
    LLayerGroup,
    RangeSelector,
    CityInfo
  },
  data() {
    const maxCost = Math.max(...citiesData.map(it => it.cost_index));
    const minCost = Math.min(...citiesData.map(it => it.cost_index));
    
    const maxSafety = Math.max(...citiesData.map(it => it.safety_index));
    const minSafety = Math.min(...citiesData.map(it => it.safety_index));

    function normalizeCostIndex(absoluteIndex) {
      let max = maxCost - minCost;
      let curr = absoluteIndex - minCost;
      return maxScore - Math.trunc(curr * maxScore / max) + 1;
    }

    function normalizeSafetyIndex(absoluteIndex) {
      let max = maxSafety - minSafety;
      let curr = absoluteIndex - minSafety;
      return Math.trunc(curr * maxScore / max) + 1;
    }

    function getMonthOptions() {
      return months.map((month, i) => {
        return {value: i, text: month};
       });
    }

    return {
      name: "BootstrapVue",
      show: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      maxBounds: latLngBounds([[100,-300],[-100,300]]),
      cities: citiesData.map(it => {
        return {
          name: it.city,
          country: it.country,
          coordinates: latLng(it.lat, it.lng),
          costIndex: normalizeCostIndex(it.cost_index),
          safetyIndex: normalizeSafetyIndex(it.safety_index),
          t_avg: it.t_avg,
          t_max: it.t_max,
          t_min: it.t_min,
        };
      }),
      costIndexMin: 1,
      costIndexMax: 100,
      safetyIndexMin: 1,
      safetyIndexMax: 100,
      indexLayerVisible: false,
      monthOptions: getMonthOptions(),
      selectedMonth: new Date().getMonth(),
      tempMin: Math.min(...citiesData.map(it => it.t_avg[new Date().getMonth()])),
      tempMax: Math.max(...citiesData.map(it => it.t_avg[new Date().getMonth()])),
      months: months
    };
  },
  computed: {
    mapRoute: {
      get: function() {
        let mapParams = this.$route.params.map
        if (!mapParams || !mapParams.match(mapRouteRegex)) {
          mapParams = "@47.1017349,16.1511986,5z";
        }
        const matchedParams = mapParams.match(mapRouteRegex);
        return {'lat':matchedParams[1], 'lng':matchedParams[3], 'zoom':matchedParams[5]};
      },
      set: function(newMapParams) {
        const newMapParamsStr = "@" + newMapParams['lat'] +","+newMapParams['lng'] +","+ newMapParams['zoom'] + "z";

        // console.log(newMapParamsStr);
        if (newMapParamsStr != this.$route.params.map) {
          this.$router.replace({
            name: 'home',
            params: {map: newMapParamsStr}
          });
        }
      }
    },
    center: {
      get: function() {
        const routeParams = this.mapRoute;
        return [parseFloat(routeParams['lat']), parseFloat(routeParams['lng'])];
      },
      set: function(center) {
        const newMapRoute = this.mapRoute;
        newMapRoute['lat'] = center.lat.toString();
        newMapRoute['lng'] = center.lng.toString();
        this.mapRoute = newMapRoute;
      }
    },
    zoom: {
      get: function () {
        return parseInt(this.mapRoute['zoom']);
      },
      set: function(zoom) {
        const newMapRoute = this.mapRoute;
        newMapRoute['zoom'] = zoom.toString();
        this.mapRoute = newMapRoute;
      }
    },
    circleRadius() {
        return this.zoom * 2;
    },
    circleLabelSize() {
      return this.circleRadius*2;
    },
    circleLabelFontSize() {
      return this.circleRadius*0.8;
    },
    circleLabelOpacity() {
      return this.zoom/10;
    },
    minTempLimit() {
      return Math.min(...citiesData.map(it => it.t_avg[this.selectedMonth]));
    },
    maxTempLimit() {
      return Math.max(...citiesData.map(it => it.t_avg[this.selectedMonth]));
    },
    citiesComputed: {
      get: function() {
       return this.cities
       .filter(it => {
        return it.costIndex >= this.costIndexMin && it.costIndex <= this.costIndexMax;
      })
      .filter(it => {
        return it.safetyIndex >= this.safetyIndexMin && it.safetyIndex <= this.safetyIndexMax;
      })
      .filter(it => {
        return it.t_avg[this.selectedMonth] >= this.tempMin && it.t_avg[this.selectedMonth] <= this.tempMax;
      })
      .map(it => {
        it.overallIndex = Math.round((it.costIndex + it.safetyIndex) / 2);
        return it;
      })
      .map(it => {
        it.color = getColor(it);
        return it;
      });
      }
    },
  },
  currentUrl() {
      return encodeURIComponent('https://liveability.romanvlasenko.com/#'+this.$route.fullPath);
  },
  watch: {
    show(newVal) {
      console.log(`Alert is now ${newVal ? "visible" : "hidden"}`);
    }
  },
  methods: {
    toggle() {
      console.log("Toggle button clicked");
      this.show = !this.show;
    },
    dismissed() {
      console.log("Alert dismissed");
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
      this.indexLayerVisible = (zoom >= 7);
    },
    centerUpdated (center) {
      this.center = center;
    },
    boundsUpdated (bounds) {
      this.bounds = bounds;
    },
    modalShown() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        this.$refs.mymap.mapObject.invalidateSize(); 
      }, 100);
    }
  }
};
</script>
